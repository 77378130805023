import axios from "axios";

const customAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
            localStorage.getItem('token')
        }`,
    },
});
customAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/";
        window.alert("로그인이 필요한 페이지입니다.");
        return
      }
      return Promise.reject(error);
    }
)
const useAxios = () => {
  return customAxios;
};

export default useAxios;
